import React from 'react';
import styled from '@emotion/styled';

import * as utils from '@utils';
import ProductsFunctionsItemWrapper from '@components/products/ProductsFunctionsItemWrapper';
import Container from '@components/layout/Container';
import { BranchBulletsItem, BranchBulletsCirclesAnimation } from '@content/types/branch';

const Wrapper = styled.div`
  position: relative;
  max-width: 1095px;
  margin-left: auto;
  margin-right: auto;

  p {
    line-height: 1.5;
  }

  &.bullets-fundingPage {
    margin-top: 140px;
  }
`;

interface Props {
  reverse?: boolean;
  bullets: BranchBulletsItem[];
  noLastDot?: boolean;
  bulletsStart?: boolean;
  bulletsEnd?: boolean;
  imageAlt?: string;
  fundingPage?: boolean;
  circles?: BranchBulletsCirclesAnimation[];
  aboutUsPage?: boolean;
  langcode: string;
  urls: Array<string>;
  video?: string;
}

const BranchBullets = (props: Props) => {
  return (
    <Container>
      <Wrapper
        className={`
        ${props.noLastDot ? 'last-dot' : ''}
        ${props.fundingPage && 'bullets-fundingPage'}
      `}
      >
        {props.bullets.map((bullet, i) => (
          <ProductsFunctionsItemWrapper
            key={i}
            image={bullet.image ? bullet.image.childImageSharp : null}
            imageAlt={bullet.imageAlt}
            left={props.reverse ? i % 2 === 0 : i % 2 === 1}
            bulletsStart={props.bulletsStart}
            bulletsEnd={props.bulletsEnd}
            nolastDot={props.noLastDot}
            buttonLabel={bullet.buttonLabel}
            buttonHref={bullet.buttonHref}
            bonusImages={bullet.bonusImages}
            fundingPage={props.fundingPage}
            disabled={bullet.disabled}
            disabledText={bullet.disabledText}
            index={i}
            circles={props.circles}
            aboutUsPage={props.aboutUsPage}
            langcode={props.langcode}
            urls={props.urls}
            video={bullet.video}
          >
            {utils.SafeHtml(bullet.paragraph)}
          </ProductsFunctionsItemWrapper>
        ))}
      </Wrapper>
    </Container>
  );
};

export default BranchBullets;
