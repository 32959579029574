import React from 'react';
import styled from '@emotion/styled';

import * as utils from '@utils';
import ButtonLink from '@components/common/ButtonLink';
import { fluidRange, respondFrom, breakpoints, css } from '@styles';

const Wrapper = styled.div`
  text-align: center;
  ${fluidRange('marginBottom', '50px', '80px')}
  padding-top: 25px;

  ${respondFrom(
    breakpoints.md,
    css`
      padding-top: 0;
    `
  )}
`;
const StyledParagraphWrapper = styled.div`
  ${fluidRange('fontSize', '20px', '35px')}
  line-height: 1.4;
  margin-bottom: 0.4em;
`;

interface Props {
  paragraph: string;
  buttonText: string;
  buttonRoute: string;

  className?: string;
}

const TextButton: React.FC<Props> = (props: Props) => (
  <Wrapper className={props.className}>
    <StyledParagraphWrapper>{utils.SafeHtml(props.paragraph)}</StyledParagraphWrapper>
    <ButtonLink to={props.buttonRoute} color="primary">
      {props.buttonText}
    </ButtonLink>
  </Wrapper>
);

export default TextButton;
