/* eslint-disable @typescript-eslint/camelcase */
import get from 'lodash.get';
import {
  Site_Setting_Entity__AccessoriesConnection,
  Site_Setting_Entity__AccessoriesEdge,
  Site_Setting_Entity__Accessories,
  Paragraph__Bulletsparagraph__Text_ButtonUnion as ParagraphSectionType,
  Paragraph__Bullets,
  Paragraph__Text_Button,
} from '../../graphql/types';
import {
  AccessoriesPageProps,
  AccessoriesPageSection,
  AccessoriesPageSectionTextButton,
  AccessoriesPageSectionBullet,
} from '@content/types/accessoriesPage';
import { FileImageSharp } from '@content/types/api';

export const transformAccessoriesPageData = (
  accessoriesPageData?: Site_Setting_Entity__AccessoriesConnection | null
): AccessoriesPageProps | null => {
  const accessoriesPageLanguages = {};

  if (!accessoriesPageData) {
    return null;
  }

  accessoriesPageData.edges.forEach((edge: Site_Setting_Entity__AccessoriesEdge) => {
    const data = edge.node;

    if (!data) {
      return null;
    }

    const fileHeroImage = get(data, 'relationships.field_hero_image') as FileImageSharp;

    accessoriesPageLanguages[data.langcode] = {
      seo: {
        title: get(data, 'field_meta_title', ''),
        description: get(data, 'field_meta_description', ''),
        image: get(data, 'relationships.field_main_image.localFile.publicURL'),
      },
      id: get(data, 'drupal_id', ''),
      heroHeadline: get(data, 'field_hero_headline.value', ''),
      heroImage: fileHeroImage ? fileHeroImage.localFile : null,
      heroParagraph: get(data, 'field_hero_text.value', ''),
      heroButtonText: get(data, 'field_hero_button_text', ''),
      sections: (get(data, 'relationships.field_components', []) as ParagraphSectionType[])
        .map(section => {
          if (!section.internal) {
            return undefined;
          }
          const { type } = section.internal;

          if (type === 'paragraph__bullets') {
            const s = section as Paragraph__Bullets;
            const fileImage = get(section, 'relationships.field_image') as FileImageSharp;
            return {
              type: 'bullet',
              image: fileImage ? fileImage.localFile : null,
              paragraph: get(s, 'field_text.value', ''),
              video: get(s, 'field_video', ''),
            } as AccessoriesPageSectionBullet;
          } else if (type === 'paragraph__text_button') {
            const s = section as Paragraph__Text_Button;
            return {
              type: 'text_button',
              buttonText: get(s, 'field_nc_button_text', ''),
              text: get(s, 'field_text.value', ''),
            } as AccessoriesPageSectionTextButton;
          }
        })
        .filter(section => typeof section !== typeof undefined) as AccessoriesPageSection[],
    };
  });

  return accessoriesPageLanguages;
};
