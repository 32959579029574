import React from 'react';
import styled from '@emotion/styled';
import { colors, fluidRange, mixins, respondFrom, respondTo, breakpoints, css } from '@styles';
import Image, { GatsbyImageProps } from 'gatsby-image';
import { BranchBulletsCirclesAnimation } from '@content/types/branch';
import ButtonLink from '@components/common/ButtonLink';
import { useSpring, animated, config } from 'react-spring';
import BlockedBoxMessage from '@components/common/BlockedBoxMessage';
import AnimationBranchBulletLine from '@animations/AnimationBranchBulletLine';
import AnimationCircle from '@animations/AnimationCircle';
import * as utils from '@utils';
import VideoPlayer from '@components/common/VideoPlayer';

const FunctionItemWrapper = styled.div`
  position: relative;
  max-width: 1095px;
  padding-bottom: 100px;
  margin-left: auto;
  margin-right: auto;

  /* mobile dot on end of component */
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    width: 1px;
    height: 50px;
    background-color: ${colors.green.dark};
    margin: auto;
  }

  /* mobile line on end of component */
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    ${mixins.gradientGreen};
    margin: auto;
    z-index: 1;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      display: flex;
      flex-flow: row;
      align-items: center;

      &:last-of-type {
        margin-bottom: 60px;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;

          left: 0;
          right: 0;
          margin: auto;
          background-color: ${colors.green.dark};
          ${mixins.gradientGreen}
          width: 7px;
          height: 7px;
          border-radius: 50%;
        }
      }

      &::before,
      &::after {
        content: none;
      }

      > div {
        flex: 1;
      }

      &.bullets-start {
        &:first-of-type {
          padding-top: 100px;
        }
      }

      &.bullets-end {
        &:last-of-type {
          padding-bottom: 80px;
        }
      }

      &.no-las-dot {
        &::after {
          content: none;
        }

        ${respondFrom(
          breakpoints.md,
          css`
            &:last-of-type {
              margin-bottom: 40px;
            }
          `
        )}
      }

      &.side-left {
        flex-flow: row-reverse;
      }
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      &.storytell-end {
        &:last-of-type {
          &::after {
            width: 43vw;
            max-width: 595px;
            height: 30px;
            left: 0;
            right: -1px;
            /* left: 50%; */
            transform: translateX(-50%);
            border-radius: 0;
            background-image: none;
            background-color: transparent;
            border: 1px solid ${colors.green.dark};
            border-top: 0;
            border-left: 0;
            border-radius: 0 0 23px 0;
          }
        }
      }
    `
  )}

  &.no-dots {
    &::before,
    &::after {
      display: none;
    }
  }

  &.bullets-fundingPage {
    padding-bottom: 40px;
    margin-bottom: 60px;

    &::after,
    &::before {
      content: none;
    }
  }

  &.bullets-aboutUsPage {
    min-height: 435px;
  }
`;

const FunctionItemImage = styled.div`
  width: 100%;
  margin-bottom: 20px;

  &.side-left {
    order: 1;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      ${fluidRange('paddingRight', '20px', '70px')}
      padding-left: 0;
      margin-bottom: 0;

      &.side-left {
        order: 2;
        padding-right: 0;
        ${fluidRange('paddingLeft', '20px', '70px')}
      }
    `
  )}

  &.bullets-fundingPage {
    background-color: ${colors.grayNurse};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
    order: 1;
    position: relative;
    max-width: 475px;

    ${respondFrom(
      breakpoints.md,
      css`
        padding: 0;

        &.side-left {
          padding: 0;
        }
      `
    )}

    img {
      object-fit: contain !important;
    }

    .gatsby-image-wrapper {
      height: 10vw !important;

      ${respondTo(
        breakpoints.lg,
        css`
          height: 15vw !important;
        `
      )}
    }

    ${respondFrom(
      breakpoints.lg,
      css`
        min-height: 360px;

        &.side-left {
          order: 2;
        }
        &.side-right {
          order: 1;
        }
      `
    )}

    &.side-left {
      margin-left: auto;
      padding: 0;
    }
  }

  &.disabled {
    .gatsby-image-wrapper {
      opacity: 0.25;
    }
  }
`;

const FunctionItemContent = styled.div`
  line-height: 1.5;

  &.side-left {
    order: 2;
    margin-left: 0;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      ${fluidRange('paddingLeft', '20px', '70px')};
      padding-right: 0;

      > div {
        max-width: 475px;
      }

      &.side-left {
        order: 1;
        padding-left: 0;
        ${fluidRange('paddingRight', '20px', '70px')}
      }
    `
  )}
`;

const ProductFunctionsIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  img {
    max-height: 40px;
  }
`;

const AnimationCircleWrapper = styled.div<ItemSideProps>`
  position: absolute;
  bottom: -10px;
  ${props => (props.left ? 'right: -140px;' : 'left: -140px')};
  transform: rotate(${props => props.rotate}deg);
  transform-origin: center;

  &.foundingPage-circle {
    left: ${props => (props.side === 'left' ? '-250px' : 'auto')};
    right: ${props => (props.side === 'left' ? 'auto' : '-450px')};
    bottom: auto;
    top: -130px;

    &-0 {
      top: -20px;
    }
  }

  &.side {
    &-center {
      max-height: 425px;
      top: -80px;
      bottom: 0;
      right: ${props => (props.left ? 'auto' : '60%')};
      left: ${props => (props.left ? '60%' : 'auto')};
      transform: rotate(${props => props.rotate}deg);
      margin: auto;
    }
  }
`;

interface ProductFunctionsItemProps {
  children: React.ReactElement;
  image: GatsbyImageProps | null;
  left: boolean;
  storytellEnd?: boolean;
  noDots?: boolean;
  bulletsStart?: boolean;
  bulletsEnd?: boolean;
  imageAlt?: string;
  nolastDot?: boolean;
  isVisible: boolean;
  buttonLabel?: string;
  buttonHref?: string;
  bonusImages?: Array<string>;
  fundingPage?: boolean;
  disabled?: boolean;
  disabledText?: string;
  index: number;
  circles?: BranchBulletsCirclesAnimation[];
  aboutUsPage?: boolean;
  langcode: string;
  urls: Array<string>;
  video?: string;
}

interface ItemSideProps {
  left: boolean;
  rotate: number;
  side?: string;
}

const ProductFunctionsItem = ({
  children,
  image,
  left,
  storytellEnd,
  noDots,
  bulletsStart,
  bulletsEnd,
  imageAlt,
  nolastDot,
  isVisible,
  bonusImages,
  buttonLabel,
  buttonHref,
  fundingPage,
  disabled,
  disabledText,
  index,
  circles,
  aboutUsPage,
  langcode,
  urls,
  video,
}: ProductFunctionsItemProps) => {
  const props1 = useSpring({
    from: { opacity: 0, transform: `translateX(${!left ? '-' : ''}20px)` },
    to: {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? 'translateX(0)' : `translateX(${!left ? '-' : ''}20px)`,
    },
    config: config.subtle,
  });

  const props2 = useSpring({
    from: { opacity: 0, transform: `translateX(${left ? '-' : ''}20px)` },
    to: {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? 'translateX(0)' : `translateX(${left ? '-' : ''}20px)`,
    },
    config: config.subtle,
    delay: 300,
  });

  return (
    <FunctionItemWrapper
      className={`
        ${left ? 'side-left' : ''} 
        ${storytellEnd ? 'storytell-end' : ''}
        ${noDots ? 'no-dots' : ''}
        ${bulletsStart && 'bullets-start'}
        ${bulletsEnd && 'bullets-end'}
        ${nolastDot && 'no-las-dot'}
        ${fundingPage && 'bullets-fundingPage'}
        ${aboutUsPage && 'bullets-aboutUsPage'}
      `}
    >
      {circles &&
        circles.map(
          (c, i) =>
            index === c.position && (
              <AnimationCircleWrapper
                left={left}
                side={c.side}
                key={i}
                rotate={c.rotate}
                className={`${
                  fundingPage ? `foundingPage-circle foundingPage-circle-${c.position}` : ''
                } side-${c.side}`}
              >
                <AnimationCircle
                  sectionVisible={isVisible}
                  className={`size-${c.size}`}
                  animateReverse={true}
                />
              </AnimationCircleWrapper>
            )
        )}

      <animated.div style={props1}>
        <FunctionItemImage
          className={`
          ${left ? 'side-left' : ''}
          ${fundingPage && 'bullets-fundingPage'}
          ${disabled && 'disabled'}
        `}
        >
          {video ? (
            <VideoPlayer image={image} imageAlt={imageAlt} videoUrl={video} />
          ) : (
            !!image && <Image {...image} alt={imageAlt} />
          )}
          {disabled && <BlockedBoxMessage blocked={true}>{disabledText}</BlockedBoxMessage>}
        </FunctionItemImage>
      </animated.div>

      <AnimationBranchBulletLine
        sectionVisible={isVisible}
        noDots={noDots}
        fundingPage={fundingPage}
        left={left}
      />

      <animated.div style={props2}>
        <FunctionItemContent className={left ? 'side-left' : ''}>
          <div>
            {children}
            {bonusImages && bonusImages.length > 0 && (
              <ProductFunctionsIcons>
                {bonusImages.map((img, index) => (
                  <img src={img} alt="" key={index} />
                ))}
              </ProductFunctionsIcons>
            )}
            {buttonLabel && buttonLabel !== '' && buttonHref && buttonHref !== '' && (
              <ButtonLink to={utils.langLink(langcode, buttonHref)} icon="arrow-right" color="text">
                {buttonLabel}
              </ButtonLink>
            )}
          </div>
        </FunctionItemContent>
      </animated.div>
    </FunctionItemWrapper>
  );
};

export default ProductFunctionsItem;
