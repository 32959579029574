import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

import * as utils from '@utils';
import { Stylable, HasChildren } from '@content/types/general';

export interface WaypointDependent {
  sectionVisible?: boolean;
}

interface Props extends Stylable, HasChildren {
  onChange?: Function;
  offset?: number;
}

const AnimationTriggerWaypoint: React.FC<Props> = props => {
  const [visibility, setVisibility] = useState(false);

  return (
    <>
      <Waypoint
        scrollableAncestor={typeof window !== typeof undefined ? window : null}
        // topOffset={utils.getVerticalAnimTriggerOffset()}
        bottomOffset={
          props.offset
            ? utils.getVerticalAnimTriggerOffset() - props.offset
            : utils.getVerticalAnimTriggerOffset()
        }
        onEnter={() => {
          setVisibility(true);
          if (typeof props.onChange === 'function') {
            props.onChange(true);
          }
        }}
        // onLeave={() => {
        //   // setVisibility(false);
        //   // if (typeof props.onChange === 'function') {
        //   //   props.onChange(false);
        //   // }
        // }}
      />

      {/* add class and param to children */}
      {React.Children.map(props.children, c => {
        // assuming ReactElement is quite optimistic - but works for our cases (check React.ReactNode for other possible types)
        return React.cloneElement(c as React.ReactElement, {
          className: visibility ? '' : 'invisible',
          sectionVisible: visibility,
        });
      })}
    </>
  );
};
export default AnimationTriggerWaypoint;
