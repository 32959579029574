import React, { Component } from 'react';
import {
  AccessoriesPageSection,
  AccessoriesPageSectionTextButton,
} from '@content/types/accessoriesPage';
import { BranchBulletsItem } from '@content/types/branch';
import TextButton from './TextButton';
import BranchBullets from '@components/branches/sections/BranchBullets';
import * as utils from '@utils';

interface AccessoriesSectionsProps {
  sections: AccessoriesPageSection[];
  langcode: string;
  urls: Array<string>;
}

class AccessoriesSections extends Component<AccessoriesSectionsProps> {
  getSections = () => {
    const { sections } = this.props;
    const arr = [[] as AccessoriesPageSection[]];
    let currentType = sections[0].type;

    sections.forEach((section, index) => {
      if (index === 0) {
        arr[0].push(section);
      } else if (section.type === currentType) {
        arr[arr.length - 1].push(section);
      } else {
        arr.push([section]);
        currentType = section.type;
      }
    });

    return arr;
  };

  checkBulletsReverse = (sections: Array<AccessoriesPageSection[]>, index: number) => {
    let reverse = false;
    let bulletsSum = 0;

    sections.forEach((s, i) => {
      if (i < index && s[0].type === 'bullet') {
        bulletsSum = bulletsSum + s.length;
      }
    });

    if (bulletsSum !== 0) {
      reverse = bulletsSum % 2 !== 0;
    }

    return reverse;
  };

  render() {
    return (
      <div>
        {this.getSections().map((sections, i) => {
          if (sections[0].type === 'text_button') {
            return sections.map((section, index) => {
              const textBtn = section as AccessoriesPageSectionTextButton;
              return (
                <TextButton
                  key={`text_button-${index}`}
                  paragraph={textBtn.text}
                  buttonText={textBtn.buttonText}
                  buttonRoute={utils.langLink(this.props.langcode, this.props.urls[4])}
                />
              );
            });
          } else if (sections[0].type === 'bullet') {
            const bullet = (sections as unknown) as BranchBulletsItem[];
            return (
              <BranchBullets
                key={`bullet-${i}`}
                bullets={bullet}
                reverse={this.checkBulletsReverse(this.getSections(), i)}
                noLastDot={true}
                circles={
                  i === 0
                    ? [{ position: 0, rotate: 0, size: 'medium' }]
                    : [{ position: 1, rotate: 0, size: 'medium' }]
                }
                langcode={this.props.langcode}
                urls={this.props.urls}
              />
            );
          }
        })}
      </div>
    );
  }
}

export default AccessoriesSections;
