import { graphql, useStaticQuery } from 'gatsby';
import * as accessoriesPageTransformers from '@content/transformers/AccessoriesPageTransformers';
import { AccessoriesPageProps } from '@content/types/accessoriesPage';

const AccessoriesQuery = (): AccessoriesPageProps => {
  const accessoriesData = useStaticQuery(graphql`
    query {
      # api data
      allSiteSettingEntityAccessories {
        edges {
          node {
            ...IposApiAccessoriesPageFragment
          }
        }
      }
    }
  `);

  return accessoriesPageTransformers.transformAccessoriesPageData(
    accessoriesData.allSiteSettingEntityAccessories
  );
};

export default AccessoriesQuery;
