import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions } from '@styles';

const BlockedMessage = styled.div<StylesProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  right: 20px;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: ${props => (props.blocked ? 'flex' : 'none')};
  opacity: 1 !important;
  z-index: 5;

  > * {
    display: block;
    padding: 25px;
    margin: 0;
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
    border: 1px solid ${colors.gray};
    background-color: ${colors.grayNurse};
  }
`;

interface StylesProps {
  blocked?: boolean;
}

interface Props extends StylesProps {
  children: any;
}

const BadgeRecommended = (props: Props) => (
  <BlockedMessage blocked={props.blocked || false}>
    <span>{props.children}</span>
  </BlockedMessage>
);

export default BadgeRecommended;
