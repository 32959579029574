import React from 'react';

import Page from '@components/layout/Page';
import AccessoriesPageComponent from '@components/accessoriesPage';
import useAccessoriesPageQuery from '@graphql/queries/AccessoriesPageQuery';
import MetaPage from '@components/common/MetaPage';

import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import { PageContext } from '@content/types/pageContext';

interface Props extends LocationDependent {
  pageContext: PageContext;
}

export default (props: Props) => {
  const accessoriesPageData = useAccessoriesPageQuery()[props.pageContext.langcode];

  return (
    <Page>
      <MetaPage
        title={accessoriesPageData ? accessoriesPageData.seo.title : ''}
        description={accessoriesPageData ? accessoriesPageData.seo.description : ''}
        image={accessoriesPageData ? accessoriesPageData.seo.image : undefined}
      />
      <DataLayer location={props.location} />
      {!!accessoriesPageData && (
        <AccessoriesPageComponent
          {...accessoriesPageData}
          langcode={props.pageContext.langcode}
          urls={props.pageContext.allUrls}
        />
      )}
    </Page>
  );
};
