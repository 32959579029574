import React from 'react';
import styled from '@emotion/styled';
import { colors, mixins, fluidRange, breakpoints, css, respondFrom, animation } from '@styles';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from '@animations/AnimationTriggerWaypoint';

const BranchBulletLine = styled.div<BranchBulletLineProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 50px;
  display: none;

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
    `
  )}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 1px;
    background-color: ${colors.green.dark};
    margin: auto;
    transform: ${props => (props.sectionVisible ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: top;
    ${mixins.transitionDefault};
    transition-delay: ${animation.fast}ms;

    @supports (-ms-ime-align: auto) and (-webkit-mask: url()) {
      width: 0.5px;
    }
  }

  &.funding-left {
    &::before {
      bottom: 35px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 1px;
      height: 20px;
      background-color: ${colors.green.dark};
      margin: auto;
      transform: ${props => (props.sectionVisible ? 'scaleY(1)' : 'scaleY(0)')};
      transform-origin: top;
      ${mixins.transitionDefault};
      transition-delay: ${animation.fast * 2.5}ms;
    }
  }
`;

const FunctionItemDot = styled.span<BranchBulletLineProps>`
  display: none;
  position: absolute;
  top: 0;
  bottom: 100px;
  left: 0;
  right: 0;
  width: 25px;
  ${fluidRange('height', '40px', '75px')}
  margin: auto;
  background-color: ${colors.white};

  ${mixins.transitionDefault};
  opacity: ${props => (props.sectionVisible ? '1' : '0')};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${fluidRange('width', '10px', '25px')}
    ${fluidRange('height', '10px', '25px')}
    border-radius: 100%;
    background-color: ${colors.green.dark};
    ${mixins.gradientGreen}
    margin: auto;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
    `
  )}
`;

interface Props extends Stylable, WaypointDependent {
  sectionVisible: boolean;
  noDots: boolean | undefined;
  fundingPage?: boolean;
  left?: boolean;
}

interface BranchBulletLineProps {
  sectionVisible: boolean;
}

export default (props: Props) => {
  return (
    <BranchBulletLine
      sectionVisible={props.sectionVisible}
      className={`
      ${props.left && props.fundingPage && 'funding-left'}
    `}
    >
      {!props.noDots && !props.fundingPage ? (
        <FunctionItemDot sectionVisible={props.sectionVisible} />
      ) : null}
    </BranchBulletLine>
  );
};
