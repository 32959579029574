import React from 'react';
import styled from '@emotion/styled';
import { mixins, colors, respondTo, css, breakpoints } from '@styles';
import { useSprings, animated, config } from 'react-spring';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from '@animations/AnimationTriggerWaypoint';

const CircleWrapper = styled.div<WrapperProps>`
  position: relative;

  ${respondTo(
    breakpoints.lg,
    css`
      display: none;
    `
  )}

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    opacity: ${props => (props.sectionVisible ? 1 : 0)};
    ${mixins.gradientGreen};
    ${mixins.transitionDefault};
    transition-delay: 2700ms;
  }

  &::before {
    top: 150px;
    left: 33px;
  }

  &::after {
    top: 150px;
    right: 0;
    background-image: none;
    background-color: ${colors.ui.light};
  }

  &.size {
    &-big {
      svg {
        height: 436px;
        width: 436px;
      }

      &::before {
        top: 209px;
        left: 47px;
      }

      &::after {
        top: 215px;
        right: 0;
      }
    }

    &-large {
      svg {
        height: 600px;
        width: 600px;
      }

      &::before {
        top: 59px;
        left: 295px;
        transition-delay: 1000ms;
      }

      &::after {
        top: auto;
        bottom: 8px;
        right: 300px;
        transition-delay: 1000ms;
      }
    }
  }
`;

interface Props extends Stylable, WaypointDependent {
  animateReverse?: boolean;
}
interface WrapperProps {
  sectionVisible: boolean;
}

const strokeDasharrays = [1000, 100];
const initialDelay = 300;

export default (props: Props) => {
  const [animLines, animLinesSet] = useSprings(2, i => ({
    from: props.animateReverse
      ? { strokeDashoffset: (i === 0 ? 1 : -1) * strokeDasharrays[i] }
      : { strokeDashoffset: (i === 0 ? -1 : 1) * strokeDasharrays[i] },
    config: config.molasses,
  }));
  animLinesSet(i => ({
    strokeDashoffset: props.animateReverse
      ? props.sectionVisible
        ? 0
        : (i === 0 ? 1 : -1) * strokeDasharrays[i]
      : props.sectionVisible
      ? 0
      : (i === 0 ? -1 : 1) * strokeDasharrays[i],
    delay: initialDelay,
  }));

  return (
    <CircleWrapper className={props.className} sectionVisible={props.sectionVisible}>
      <svg xmlns="http://www.w3.org/2000/svg" width="317" height="317" viewBox="0 0 317 317">
        <g fill="none" fillRule="evenodd">
          <animated.path
            strokeDasharray={strokeDasharrays[0]}
            strokeDashoffset={animLines[0].strokeDashoffset}
            stroke="#094"
            d="M36.3867894,153.509121 C36.3867894,220.335576 90.5603347,274.509121 157.386789,274.509121 L157.386789,274.509121 C224.213244,274.509121 278.386789,220.335576 278.386789,153.509121 C278.386789,86.6826664 224.213244,32.5091211 157.386789,32.5091211"
          />
          <animated.path
            strokeDasharray={strokeDasharrays[0]}
            strokeDashoffset={animLines[0].strokeDashoffset}
            stroke="#C9C9C9"
            d="M314.55601,157.110699 C314.55601,70.4019935 244.264715,0.110699201 157.55601,0.110699201 C70.847304,0.110699201 0.556009757,70.4019935 0.556009757,157.110699 C0.556009757,243.819405 70.847304,314.110699 157.55601,314.110699 L157.55601,314.110699"
          />
        </g>
      </svg>
    </CircleWrapper>
  );
};
