import React from 'react';

import * as utils from '@utils';
import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import { AccessoriesPageProps } from '@content/types/accessoriesPage';
import Hero from '@components/common/Hero';
import AccessoriesSections from '@components/accessoriesPage/AccessoriesSections';

export interface Props extends AccessoriesPageProps {
  langcode: string;
  urls: Array<string>;
}

export default (props: Props) => (
  <Page>
    {!!props.id && (
      <>
        <Hero
          size="medium"
          title={props.heroHeadline}
          image={props.heroImage ? props.heroImage.childImageSharp : undefined}
          buttonText={props.heroButtonText || 'Kup teraz'}
          buttonRoute={utils.langLink(props.langcode, props.urls[4])}
          paragraph={utils.SafeHtml(props.heroParagraph)}
          theme="dark"
        ></Hero>

        <Container>
          <AccessoriesSections
            sections={props.sections}
            langcode={props.langcode}
            urls={props.urls}
          />
        </Container>
      </>
    )}
  </Page>
);
