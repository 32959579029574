import React, { Component } from 'react';
import { GatsbyImageProps } from 'gatsby-image';
import { BranchBulletsCirclesAnimation, BulletsIcons } from '@content/types/branch';
import AnimationTriggerWaypoint from '@animations/AnimationTriggerWaypoint';
import ProductsFunctionsItem from '@components/products/ProductsFunctionsItem';

interface ProductFunctionsItemWrapperProps {
  children: React.ReactElement;
  image: GatsbyImageProps | null;
  left: boolean;
  storytellEnd?: boolean;
  noDots?: boolean;
  bulletsStart?: boolean;
  bulletsEnd?: boolean;
  imageAlt?: string;
  nolastDot?: boolean;
  buttonLabel?: string;
  buttonHref?: string;
  bonusImages?: Array<string>;
  fundingPage?: boolean;
  disabled?: boolean;
  disabledText?: string;
  index: number;
  circles?: BranchBulletsCirclesAnimation[];
  aboutUsPage?: boolean;
  icons?: BulletsIcons[];
  langcode: string;
  urls: Array<string>;
  video?: string;
}

class ProductFunctionsItem extends Component<ProductFunctionsItemWrapperProps> {
  state = {
    isVisible: false,
  };

  render() {
    return (
      <AnimationTriggerWaypoint onChange={() => this.setState({ isVisible: true })}>
        <ProductsFunctionsItem isVisible={this.state.isVisible} {...this.props} />
      </AnimationTriggerWaypoint>
    );
  }
}

export default ProductFunctionsItem;
